export enum TODOS_ACTIONS {
  ADD_TASK = "add_task",
  EDIT_TASK = "edit_task",
  DELETE_TASK = "delete_task",
  COMPLETE_TASK = "complete_task",
  DELETE_COMPLETED = "delete_completed",
  MARK_ALL = "mark_all",
  EDIT_DETAILS = "edit_details",
}

export enum L_STORAGE_KEY {
  TODO_LIST = "tdlt",
  IS_ALL_MARKED = "salmkd",
}

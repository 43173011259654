import { TODOS_ACTIONS } from "../enums/Actions";
import { TodoModel } from "../models/TodoModel";

export function TodosReducer(todos: TodoModel[], action: any) {
  let isTheArrMarked = true;
  switch (action.type) {
    case TODOS_ACTIONS.ADD_TASK:
      action.payload.setIsAllMarked(false);
      return [...todos, new TodoModel(action.payload.name, todos)];

    case TODOS_ACTIONS.EDIT_TASK:
      return todos.map((prev: TodoModel) => {
        return prev.id === action.payload.id ? action.payload : prev;
      });

    case TODOS_ACTIONS.DELETE_TASK:
      const newTodo = todos.filter((prev: TodoModel) => {
        if (!prev.isCompleted && prev.id !== action.payload.id) {
          isTheArrMarked = false;
        }
        return prev.id !== action.payload.id;
      });
      if (action.payload.isAllMarked && !isTheArrMarked) {
        action.payload.setIsAllMarked(false);
      }
      if (!action.payload.isAllMarked && isTheArrMarked) {
        action.payload.setIsAllMarked(true);
      }
      return newTodo;
    case TODOS_ACTIONS.COMPLETE_TASK:
      const newTodos = todos.map((prev: TodoModel) => {
        const current = { ...prev };

        if (current.id === action.payload.id)
          current.isCompleted = !prev.isCompleted;

        if (!current.isCompleted) isTheArrMarked = false;

        return current;
      });
      if (action.payload.isAllMarked && !isTheArrMarked) {
        action.payload.setIsAllMarked(false);
      }
      if (!action.payload.isAllMarked && isTheArrMarked) {
        action.payload.setIsAllMarked(true);
      }
      return newTodos;

    case TODOS_ACTIONS.DELETE_COMPLETED:
      return todos.filter((prev: TodoModel) => !prev.isCompleted);

    case TODOS_ACTIONS.MARK_ALL:
      return todos.map((prev: TodoModel) => ({
        ...prev,
        isCompleted: action.payload.isCompleted,
      }));

    case TODOS_ACTIONS.EDIT_DETAILS:
      return todos.map((prev: TodoModel) => {
        return prev.id === action.payload.id
          ? { ...prev, details: action.payload.details }
          : prev;
      });

    default:
      return todos;
  }
}

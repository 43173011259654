import { useEffect, useReducer, useState } from "react";
import "./App.scss";
import Todo from "./components/todo/Todo";
import { TODOS_ACTIONS, L_STORAGE_KEY } from "./enums/Actions";
import { TodosReducer } from "./reducers/TodosReducer";

function App() {
  const [todoForm, setTodoFrom] = useState("");
  const [todos, todosDispatch] = useReducer(
    TodosReducer,
    JSON.parse(localStorage.getItem(L_STORAGE_KEY.TODO_LIST)!!) || []
  );
  const [isAllMarked, setIsAllMarked] = useState(
    localStorage.getItem(L_STORAGE_KEY.IS_ALL_MARKED) === "true"
  );

  useEffect(() => {
    localStorage.setItem(L_STORAGE_KEY.TODO_LIST, JSON.stringify(todos));
    if (todos.length === 0) setIsAllMarked(false);
  }, [todos]);

  useEffect(() => {
    localStorage.setItem(L_STORAGE_KEY.IS_ALL_MARKED, isAllMarked.toString());
  }, [isAllMarked]);

  function handleSubmit(e: any) {
    e.preventDefault();
    if (todoForm === "") return;
    todosDispatch({
      type: TODOS_ACTIONS.ADD_TASK,
      payload: {
        name: todoForm,
        isAllMarked: isAllMarked,
        setIsAllMarked: setIsAllMarked,
      },
    });
    setTodoFrom("");
  }

  function handleMarkAll() {
    if (todos.length === 0) return;
    setIsAllMarked((prev: boolean) => {
      const marked = !prev;
      todosDispatch({
        type: TODOS_ACTIONS.MARK_ALL,
        payload: { isCompleted: marked },
      });
      return marked;
    });
  }

  function handleDeleteMarked() {
    if (todos.length === 0) return;
    todosDispatch({
      type: TODOS_ACTIONS.DELETE_COMPLETED,
      payload: {},
    });
  }

  return (
    <div className="app">
      <div className="todo-card">
        <h1>Todo App</h1>
        <form className="todo-form" onSubmit={handleSubmit}>
          <div className="input-area">
            <input
              autoFocus={true}
              className="input-field"
              type="text"
              placeholder="Type task name..."
              maxLength={30}
              value={todoForm}
              onChange={(e: any) => setTodoFrom(e.target.value)}
            />
          </div>
          <button className="btn btn-add" type="submit">
            Add Task
          </button>
          <button
            type="button"
            className={`btn btn-${isAllMarked ? "edit" : "normal"}`}
            onClick={handleMarkAll}
          >
            {isAllMarked ? <span>UnMark All</span> : <span>Mark All</span>}
          </button>
          <button
            type="button"
            className="btn btn-delete"
            onClick={handleDeleteMarked}
          >
            Delete Selected
          </button>
        </form>
        <fieldset className="todos-container">
          <legend>Your Tasks</legend>
          {todos && todos.length > 0 ? (
            <>
              <ol>
                {todos.map((todo: any) => {
                  return (
                    <li key={todo.id}>
                      <Todo
                        todo={todo}
                        todosDispatch={todosDispatch}
                        isAllMarked={isAllMarked}
                        setIsAllMarked={setIsAllMarked}
                      />
                    </li>
                  );
                })}
              </ol>
            </>
          ) : (
            <p>No Tasks</p>
          )}
        </fieldset>
      </div>
    </div>
  );
}

export default App;
